    // eslint-disable-next-line import/no-anonymous-default-export
    export default{
    removeParameterByName: function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
        if (results && results[0]) {
          url = url.replace(results[0], results[0][0] === "?" ? "?" : "");
        }
        return url;
      },

      removeSensitiveParams() {
        if (!window.location.search) return;
        const sensitiveParams = ["xauthcode", "xdomainname","xauthkey"];
        let search = window.location.search;
        while (sensitiveParams.length) {
          search = this.removeParameterByName(sensitiveParams.pop(), search);
        }
        window.history.replaceState(
          null,
          null,
          window.location.pathname + search + window.location.hash
        );
      }
}