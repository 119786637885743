// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

const RadioButton = ({ value, name ,selectedValue, onChange }) => {
    const radioButtonStyle = {
        display: "flex",
        alignItems: "center", // This aligns the radio button icon with the text
        marginBottom: "5px", // Add some space between each radio button
    };
    const radioButtonInputStyle = {
        marginRight: "5px",
        cursor: "pointer", // Add pointer cursor on hover
    };

    const radioButtonTextStyle = {
        // textDecoration: selectedValue === value ? "underline" : "none", // Underline when selected
        fontWeight: selectedValue === value ? "bold" : "normal", // Bold when selected
    };
    const radioButtonHoverStyle = {
        fontSize: '20px', // Adjust the font size as needed
        fontWeight: 'bold',
      };

   
return(

    <label
  style={Object.assign(
    {},
    radioButtonStyle,
    selectedValue === value ? radioButtonHoverStyle : null
  )}
>
        <input
            type="radio"
            value={value}
            onChange={onChange}
            checked={value === selectedValue}
            style={radioButtonInputStyle}
        /> 
        
        <span style={radioButtonTextStyle}>{name}</span>
    </label>
)}
export default RadioButton;

