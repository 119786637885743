import Axios from "axios";
import env from "../env";


let region = localStorage.getItem('region')

const AuthService = {


	isKeyValid() {
		const expiryTime = window.localStorage.getItem("spaceio-key-expiry");
		const now = Date.now();
		return Number(now) <= Number(expiryTime);
	},

	async fetchAuthKey() {
		let key = AuthService.authKey;
		if (AuthService.isKeyValid()) {
			return key
		};
		const newAuthKey = await this.getRefreshToken(AuthService.refreshToken,region);
		return newAuthKey;
	},

	get refreshToken() {
		let key = window.localStorage.getItem("spaceio-refresh-token");
		if (key === null) {
			return null
		};
		return key;
	},

	set refreshToken(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-refresh-token", key);
	},

	get authKey() {
		let key = window.localStorage.getItem("spaceio-key");
		if (key === null) return null
		return key;
	},

	set authKey(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-key", key);
	},

    getAuthToken: async (authCode,region) => {

		console.log("================>",region);
		const loginURL = region === 'US' ? env.loginURLUS : env.loginURL;	
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode, 
				},
				method: 'post',
				url: loginURL
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));
			return res;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	getRefreshToken: async (refreshKey,region) => {
		const refreshTokenURL = region === 'US' ? env.refreshTokenURLUS : env.refreshTokenURL;	
		console.log("refresh token url",  refreshTokenURL, region)
		try {
			const res = await Axios({
				headers: {
					"x-refresh-token": refreshKey, 
				},
				method: 'post',
				url: refreshTokenURL
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			//console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));

			return newAuthKey;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	}
}  

export default AuthService;