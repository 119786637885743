const DownloadService = {
    downloadCsv: function (csvContent, fileName) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      this.downloadBlob(blob, `${fileName}.csv`);
    },
    _forceLinkDownload(href, fileName) {
        const link = document.createElement("a");
        link.href = href;
        link.target='_blank';
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    downloadBlob: function (blob, fileName) {
        if (navigator.msSaveBlob) {
          //for IE
          navigator.msSaveBlob(blob, fileName);
        } else {
          this._forceLinkDownload(URL.createObjectURL(blob), fileName);
        }
      },
}
export default DownloadService;