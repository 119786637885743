import React from 'react';
import freespaceIcon from "../image/freespace.svg"

const footerStyle ={
  fontWeight: "700, Bold",
  fontFamily: "Gilroy",
  style: "normal",
  fontSize: "13px",
  align: "Center",
  margin:"20px 0 0 0",
  opacity:"1",
  display:"flex",
  justifyContent:"center",
  flexDirection: "column",
  alignItems: "center",
  padding: "0rem 0 8px 0",
  // position:"relative",
  // bottom:0,
}
function Footer({style}) {
  
  
    return (
    <footer className="main-footer" style={{...footerStyle,...style}}>
      <span>Copyright &copy; {new Date().getFullYear()} <a style={{ 'color': '#202153' }} href="http://www.afreespace.com/" target="_blank" rel="noopener noreferrer"><img src={freespaceIcon} style={{verticalAlign: "middle", height: "13px"}} alt="logo"></img></a></span>
      <span>All rights reserved.</span>
    </footer>);
  }


export default Footer;

