import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
// import Login from './Components/login';
import Login2 from './Components/login/Login';
// import Card from 'react-bootstrap/Card';
// import logo from './image/freespace.png';
import Report from './Components/report';
import Select from './Components/select';
import Health from './Components/Health/Health';
import APHealth from './Components/apHealth';
import TwilioReport from "./Components/twilioSIM";
// import Footer from "./Components/footer";
// import Footer from "./Components/Footer.jsx";


class App extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var dateTime = date
    this.state = {
      time: new Date(),
      dateTime: dateTime,
      pageroute: 'Login',
      username:null, 
      password:null,
	  akey:null,
	  uskey:null,
    }
	this.routehandlying = this.routehandlying.bind(this);

  }


setroute = (gotopath,tokenKey,tokenKeyUS,username,password,type) => {
  this.setState(prevState =>({ 
	pageroute: gotopath,
	akey:prevState.akey || tokenKey,
	uskey:prevState.uskey || tokenKeyUS,
	username:prevState.username || username, 
	password: prevState.password || password,
	type: prevState.type || type,
  }))
} 

  componentDidMount() { // create the interval once component is mounted
    // this.update = setInterval(() => {
    //   this.setState({ time: new Date(), });
    // }, 1 * 1000); // every 1 seconds
  }

  componentWillUnmount() { // delete the interval just before component is removed
    clearInterval(this.update);
  }

  routehandlying(pagekey,localState){
    console.log("localState",localState)
    let componet = "";
    
    switch (pagekey) {
      case 'Login':
        componet = <Login2 setroute={this.setroute} />
        break;
      case 'Select':
        componet = <Select setroute={this.setroute} />
        break;
      case 'Health':
        componet = <Health setroute={this.setroute} />
        break; 

      case 'Report':
        componet = <Report setroute={this.setroute} username={localState.username} password ={localState.password} akey={localState.akey} uskey={localState.uskey} type={localState.type}/>
        break;
      case 'APReport':
        componet = <APHealth setroute={this.setroute} username={localState.username} password={localState.password} akey={localState.akey} uskey={localState.uskey} type={localState.type}/>
        break;
      case 'TwilioReport':
        componet = <TwilioReport setroute={this.setroute} username={localState.username} password={localState.password} akey={localState.akey} uskey={localState.uskey} type={localState.type}/>
        break;
      default:
        break;
    }
    return componet;
  }

  render() {
    return (
		<div className="container-fluid g-0">
			<div className="row justify-content-center g-0 mainDiv">
           	 {this.routehandlying(this.state.pageroute,this.state)}
      		</div>
      	</div>
      
    );
  }
}

export default App;