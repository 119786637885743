import React from "react";
import headLogo from "../image/headLogo.svg"

function Header({header,subHeader}) {
    return ( 
    <div className="header">
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center" }}>      
            <span>
                <img  src={headLogo} alt="carbon-cloud" style={{paddingBottom:"25px",visibility:"hidden"}} height="65px"/>
            </span>
            <span>
                <h3 style={{ textAlign: 'center',color:"rgb(67, 220, 148)",fontSize:"24px",fontWeight:"700" ,fontFamily: 'Gilroy'}}>{header}</h3>
                <p style={{ textAlign: 'center',color:"rgb(67, 220, 148)",fontSize:"14px",opacity:"0.8",fontWeight:"400"}}>
                {subHeader}
                </p>
            </span>
        </div>
    </div>
    );
}

export default Header;