import React from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Select extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'TIM',
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit = async () => {
		this.props.setroute('Report', null, null, null, null, this.state.type);
	}

	componentDidMount = async () => { // create the interval once component is mounted
		console.log("we are in the select");

	}

	render() {
		return (
			<div className="Login" style={{ marginBottom: 20 }}>
				<Form>
					<Form.Group controlId="formBasicSelect">
						<Form.Label>Select Device Type</Form.Label>
						<Form.Control
							as="select"
							value={this.state.type}
							onChange={e => {
								this.setState({ type: e.target.value })
							}}
						>
							<option value="TIM">TIM</option>
							<option value="FlO">FLO</option>
							<option value="TIM+">TIM+</option>
						</Form.Control>
					</Form.Group>
					<br />
					<br />
					<Button block size="lg" type="button" onClick={() => this.handleSubmit()} style={{ borderRadius: "10px", background: "linear-gradient(90deg, #F7707D 0%, #F29173 100%)", color: "#ffffff", borderColor: "transparent" }}>
						<i className="fas fa-sign-in-alt"></i>Generate {this.state.type} Health Report
					</Button>
				</Form>
			</div>
		)
	}
}
export default Select;